import React, { useReducer } from 'react'
import PayContext from './payContext'
import PayReducer from './payReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    PAYMENT_WITH_CARD,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    ADD_USER_CARD,
    GET_USER_CARD,
} from './payTypes'

const PayState = (props) => {
    const initialState = {
        responseStatus: null,
        usercards: [],
    }

    const [state, dispatch] = useReducer(PayReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const deleteUserCardAuthorize = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'deleteCardPay', formData, '')])
            if (res.data.success) {
                dispatch({
                    type: ADD_USER_CARD,
                    payload: 'carddeleted',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.data.error,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const getAllUserCardsAuthorize = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'getcardprofile', formData, '')])
            if (res.data.success) {
                dispatch({
                    type: GET_USER_CARD,
                    payload: res.data.results.usercard,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.data.error,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllUserCards = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'get_all_creditcard', formData, '', 'auctionpay'),
            ])
            if (res.data.success) {
                dispatch({
                    type: GET_USER_CARD,
                    payload: res.data.data,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.data.error,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const editUserCard = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'edit_creditcard', formData, '', 'auctionpay'),
            ])
            if (res.data.success) {
                dispatch({
                    type: ADD_USER_CARD,
                    payload: 'cardedited',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.data.error,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addUserCard = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'add_new_creditcard', formData, '', 'auctionpay'),
            ])
            if (res.data.success) {
                dispatch({
                    type: ADD_USER_CARD,
                    payload: 'cardadded',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.data.error,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addExtraUserCardAuthorize = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'insertExtraCard', formData, '')])
            if (res.data.success === 'yes') {
                dispatch({
                    type: ADD_USER_CARD,
                    payload: 'cardadded',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addUserCardAuthorize = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkvalidcard_firstime', formData, ''),
            ])
            if (res.data.success === 'yes') {
                dispatch({
                    type: ADD_USER_CARD,
                    payload: 'cardadded',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const paymentWithCardAuthorize = async (formData) => {
        //console.log('payment initiated', formData)
        try {
            const [res] = await Promise.all([
                apiCall('post', 'SlibuyTransPaymentnew', formData, ''),
            ])
            //console.log('response getting', res)
            if (res.data.error === 'no') {
                dispatch({
                    type: PAYMENT_WITH_CARD,
                    payload: 'paymentsuccessful',
                    // payload: res.data.data.result
                })
            } else if (res.data.error === 'yes') {
                if (res.data.errormsg.message) {
                    dispatch({
                        type: RESPONSE_STATUS,
                        payload: res.data.errormsg.message,
                    })
                } else {
                    dispatch({
                        type: RESPONSE_STATUS,
                        payload: res.data.errormsg,
                    })
                }
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.data.error,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const placeOrderStripe = async (formData) => {
        const from = 'placeOrderStripe'
        try {
            formData['mode'] = process.env.REACT_APP_PAYMENTMODE
            const [res] = await Promise.all([
                apiCall('post', 'placeOrderStripe', formData, '', 'auctionpay'),
            ])
            //console.log('response getting', res)
            if (res.data.success) {
                resp.commonResponse({ status: 'success', message: 'Payment successful' }, from)
            } else {
                if (res.data.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
                } else {
                    resp.commonErrorResponse(from)
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    //update cart after successful payment
    const successAfterPay = async (formData) => {
        const from = 'successAfterPay'
        try {
            formData['mode'] = process.env.REACT_APP_PAYMENTMODE
            formData['auction_io'] = 1
            if (global.pluginConfiguration?.salestaxcity?.enable) {
                formData['city_sales_tax_enable'] = 1
            } else {
                formData['city_sales_tax_enable'] = 0
            }
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    global.storeConfigration?.stripe_integration?.value == 1
                        ? 'successAfterPay_stripe'
                        : 'successAfterPay',
                    formData,
                    '',
                    '',
                ),
            ])
            //console.log('response getting', res)
            if (res.data.status === 'yes') {
                resp.commonResponse({ status: 'success', message: 'Payment successful!' }, from)
            } else {
                if (res.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.message }, from)
                } else if (res.data.status == 'no_records') {
                    resp.commonResponse(
                        {
                            status: 'error',
                            message: 'Buynow option has been disabled for this product by seller.',
                        },
                        from,
                    )
                } else {
                    resp.commonErrorResponse(from)
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const successAfterPayCalculation = async (formData) => {
        const from = 'successAfterPayCalculation'
        try {
            formData['mode'] = process.env.REACT_APP_PAYMENTMODE
            formData['auction_io'] = 1
            if (global.pluginConfiguration?.salestaxcity?.enable) {
                formData['city_sales_tax_enable'] = 1
            } else {
                formData['city_sales_tax_enable'] = 0
            }
            const [res] = await Promise.all([
                apiCall('post', 'successAfterPayCalculation', formData, '', 'api_buyer'),
            ])
            //console.log('response getting', res)
            if (res.data.status === 'yes') {
                resp.commonResponse({ status: 'success', message: 'Payment successful!' }, from)
            } else {
                if (res.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.message }, from)
                } else if (res.data.status == 'no_records') {
                    resp.commonResponse(
                        {
                            status: 'error',
                            message: 'Buynow option has been disabled for this product by seller.',
                        },
                        from,
                    )
                } else {
                    resp.commonErrorResponse(from)
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const successAfterPayAuctionCalculation = async (formData) => {
        const from = 'successAfterPayAuctionCalculation'
        try {
            formData['mode'] = process.env.REACT_APP_PAYMENTMODE
            formData['auction_io'] = 1
            if (global.pluginConfiguration?.salestaxcity?.enable) {
                formData['city_sales_tax_enable'] = 1
            } else {
                formData['city_sales_tax_enable'] = 0
            }
            const [res] = await Promise.all([
                apiCall('post', 'successAfterPayAuctionCalculation', formData, '', 'api_buyer'),
            ])
            //console.log('response getting', res)
            if (res.data.status === 'yes') {
                resp.commonResponse(
                    { status: 'success', message: 'Payment update successful!' },
                    from,
                )
            } else {
                if (res.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.message }, from)
                } else {
                    if (res.data.message) {
                        resp.commonResponse({ status: 'error', message: res.data.message }, from)
                    } else {
                        resp.commonErrorResponse(from)
                    }
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    //update cart after successful payment
    const successAfterPayAuction = async (formData) => {
        const from = 'successAfterPayAuction'
        try {
            formData['mode'] = process.env.REACT_APP_PAYMENTMODE
            formData['auction_io'] = 1
            if (global?.pluginConfiguration?.return_assurance?.enable) {
                formData.return_assurance = 1
            }
            if (global?.storeConfigration?.include_buyer_protection_fee_for_sales_tax?.value) {
                formData.include_buyer_protection_fee_for_sales_tax = 1
            }

            if (global.pluginConfiguration?.salestaxcity?.enable) {
                formData['city_sales_tax_enable'] = 1
            } else {
                formData['city_sales_tax_enable'] = 0
            }
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    global.storeConfigration?.stripe_integration?.value == 1
                        ? 'successAfterPayAuction_stripe'
                        : 'successAfterPayAuction',
                    formData,
                    '',
                    '',
                ),
            ])
            if (res.data.status === 'yes') {
                resp.commonResponse({ status: 'success', message: 'Payment successful!' }, from)
            } else {
                if (res.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.message }, from)
                } else {
                    resp.commonErrorResponse(from)
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const successAfterPayupdateforbank = async (formData) => {
        const from = 'successAfterPay'
        try {
            formData['auction_io'] = 1
            const [res] = await Promise.all([
                apiCall('post', 'successAfterPay_updatebank', formData, '', ''),
            ])
            //console.log('response getting', res)
            if (res.data.status === 'yes') {
                resp.commonResponse({ status: 'success', message: 'Payment successful!' }, from)
            } else {
                if (res.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.message }, from)
                } else {
                    resp.commonErrorResponse(from)
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const successAfterPayAuctionforbank = async (formData) => {
        const from = 'successAfterPayAuction'
        try {
            formData['auction_io'] = 1
            const [res] = await Promise.all([
                apiCall('post', 'successAfterPayAuction__updatebank', formData, '', ''),
            ])
            if (res.data.status === 'yes') {
                resp.commonResponse({ status: 'success', message: 'Payment successful!' }, from)
            } else {
                if (res.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.message }, from)
                } else {
                    resp.commonErrorResponse(from)
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const successAfterPayAuctionPartial = async (formData) => {
        const from = 'successAfterPayAuctionPartial'
        try {
            formData['mode'] = process.env.REACT_APP_PAYMENTMODE
            formData['auction_io'] = 1
            if (global.pluginConfiguration?.salestaxcity?.enable) {
                formData['city_sales_tax_enable'] = 1
            } else {
                formData['city_sales_tax_enable'] = 0
            }
            const [res] = await Promise.all([
                apiCall('post', 'successAfterPayAuctionPartial', formData, '', 'api_buyer'),
            ])
            //console.log('response getting', res)
            if (res.data.status === 'yes') {
                resp.commonResponse({ status: 'success', message: 'Payment successful!' }, from)
            } else {
                if (res.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.message }, from)
                } else if (res.data.status == 'no_records') {
                    resp.commonResponse(
                        {
                            status: 'error',
                            message: 'Buynow option has been disabled for this product by seller.',
                        },
                        from,
                    )
                } else {
                    resp.commonErrorResponse(from)
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    //update auction after successful payment
    const successGroupInvoice = async (formData) => {
        const from = 'successGroupInvoice'
        try {
            formData['mode'] = process.env.REACT_APP_PAYMENTMODE
            const [res] = await Promise.all([
                apiCall('post', 'successGroupInvoice', formData, '', ''),
            ])
            if (res.data.success === 'yes') {
                resp.commonResponse({ status: 'success', message: 'Payment successful!' }, from)
            } else {
                if (res.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.message }, from)
                } else {
                    resp.commonErrorResponse(from)
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const paymentWithCard = async (formData) => {
        //console.log('payment initiated', formData)
        try {
            const [res] = await Promise.all([
                apiCall('post', 'zift_card_paymentapi', formData, '', 'auctionpay'),
            ])
            //console.log('response getting', res)
            if (res.data.success) {
                dispatch({
                    type: PAYMENT_WITH_CARD,
                    payload: 'paymentsuccessful',
                    // payload: res.data.data.result
                })
            } else if (res.data.error) {
                if (res.data.data.failureMessage) {
                    dispatch({
                        type: RESPONSE_STATUS,
                        payload: res.data.data.failureMessage,
                    })
                } else {
                    dispatch({
                        type: RESPONSE_STATUS,
                        payload: res.data.data.error,
                    })
                }
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.data.error,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const successAfterPayAuctionshipping = async (formData) => {
        const from = 'successAfterPayAuctionshipping'
        try {
            formData['mode'] = process.env.REACT_APP_PAYMENTMODE
            formData['auction_io'] = 1
            const [res] = await Promise.all([
                apiCall('post', 'shipping_payment_buynow', formData, '', ''),
            ])
            if (res.data.status === 'yes') {
                resp.commonResponse({ status: 'success', message: 'Payment successful!' }, from)
            } else {
                if (res.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.message }, from)
                } else {
                    resp.commonErrorResponse(from)
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const listingFee = async (formData) => {
        const from = 'listingFee'
        try {
            formData['mode'] = process.env.REACT_APP_PAYMENTMODE
            const [res] = await Promise.all([apiCall('post', 'listing_fee', formData, '', '')])
            if (res.data.success) {
                resp.commonResponse({ status: 'success', message: res.data.message }, from)
            } else {
                if (res.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.message }, from)
                } else {
                    resp.commonErrorResponse(from)
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    //update cart after successful payment
    const successAfterPaypaypalAuction = async (formData) => {
        const from = 'successAfterPaypalauction'
        formData.auction_type = 'auction'
        localStorage.setItem('paymentdetails', JSON.stringify(formData))
        try {
            formData['mode'] = process.env.REACT_APP_PAYMENTMODE
            formData['auction_io'] = 1
            if (global.pluginConfiguration?.salestaxcity?.enable) {
                formData['city_sales_tax_enable'] = 1
            } else {
                formData['city_sales_tax_enable'] = 0
            }
            const [res] = await Promise.all([
                apiCall('post', 'createPaypalOrder', formData, '', ''),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse({ status: 'success', responseData: res.data }, from)
            } else {
                if (res.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.message }, from)
                } else {
                    resp.commonErrorResponse(from)
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const successAfterPaypaypalBuynow = async (formData) => {
        const from = 'successAfterPaypalbuynow'
        formData.auction_type = 'buynow'
        localStorage.setItem('paymentdetails', JSON.stringify(formData))
        try {
            formData['mode'] = process.env.REACT_APP_PAYMENTMODE
            formData['auction_io'] = 1
            if (global.pluginConfiguration?.salestaxcity?.enable) {
                formData['city_sales_tax_enable'] = 1
            } else {
                formData['city_sales_tax_enable'] = 0
            }
            const [res] = await Promise.all([
                apiCall('post', 'createPaypalOrderBuynow', formData, '', ''),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse({ status: 'success', responseData: res.data }, from)
            } else {
                if (res.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.message }, from)
                } else {
                    resp.commonErrorResponse(from)
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    //update cart after successful payment
    const successAfterPaypaypalupdate = async (formData) => {
        const from = 'successAfterPaypalupdate'
        try {
            formData['mode'] = process.env.REACT_APP_PAYMENTMODE
            formData['auction_io'] = 1
            if (global.pluginConfiguration?.salestaxcity?.enable) {
                formData['city_sales_tax_enable'] = 1
            } else {
                formData['city_sales_tax_enable'] = 0
            }
            const [res] = await Promise.all([
                apiCall('post', 'paypalOrderCapture', formData, '', ''),
            ])
            if (res.data.status === 'yes') {
                resp.commonResponse({ status: 'success', message: 'Payment successful!' }, from)
            } else {
                if (res.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.message }, from)
                } else {
                    resp.commonErrorResponse(from)
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const successAfterPaypaypalupdatebuynow = async (formData) => {
        const from = 'successAfterPaypalupdatebuynow'
        try {
            formData['mode'] = process.env.REACT_APP_PAYMENTMODE
            formData['auction_io'] = 1
            if (global.pluginConfiguration?.salestaxcity?.enable) {
                formData['city_sales_tax_enable'] = 1
            } else {
                formData['city_sales_tax_enable'] = 0
            }
            const [res] = await Promise.all([
                apiCall('post', 'paypalOrderCaptureBuynow', formData, '', ''),
            ])
            if (res.data.status === 'yes') {
                resp.commonResponse({ status: 'success', message: 'Payment successful!' }, from)
            } else {
                if (res.data.message) {
                    resp.commonResponse({ status: 'error', message: res.data.message }, from)
                } else {
                    resp.commonErrorResponse(from)
                }
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const paypalsuccessUpdate = async (formData) => {
        const from = 'sucessselleronboardpaypal'
        try {
            formData['mode'] = process.env.REACT_APP_PAYMENTMODE
            formData['auction_io'] = 1
            if (global.pluginConfiguration?.salestaxcity?.enable) {
                formData['city_sales_tax_enable'] = 1
            } else {
                formData['city_sales_tax_enable'] = 0
            }
            const [res] = await Promise.all([apiCall('post', 'paypal_return', formData, '', '')])
            if (res.data.status === 'success') {
                resp.commonResponse({ status: 'success', message: 'Payment successful!' }, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const verifyPaypalstate = async (formData) => {
        const from = 'sucesssellerverifypaypal'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'check_paypal_merchant/' + formData.merchantIdInPayPal,
                    formData,
                    '',
                    '',
                ),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse({ status: 'success', message: res.data.response }, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const buynow_make_payment = async (formData) => {
        const from = 'buynow_make_payment'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'cart_checkerlist_new', formData, '', ''),
            ])
            if (res.data.status === 'success' && res?.data?.response?.length > 0) {
                resp.commonResponse({ status: 'success', message: res.data.response }, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const buynow_reset_payment = async (formData) => {
        const from = 'buynow_reset_payment'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'cart_checkerlist_reset', formData, '', ''),
            ])
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <PayContext.Provider
            value={{
                responseStatus: state.responseStatus,
                usercards: state.usercards,
                successAfterPayAuctionPartial,
                listingFee,
                placeOrderStripe,
                successAfterPay,
                successAfterPayAuction,
                successAfterPayupdateforbank,
                successAfterPayAuctionforbank,
                successGroupInvoice,
                getAllUserCards,
                getAllUserCardsAuthorize,
                deleteUserCardAuthorize,
                paymentWithCard,
                paymentWithCardAuthorize,
                addUserCard,
                addUserCardAuthorize,
                addExtraUserCardAuthorize,
                editUserCard,
                successAfterPayAuctionshipping,
                clearResponse,
                successAfterPayAuctionCalculation,
                successAfterPayCalculation,
                successAfterPaypaypalAuction,
                successAfterPaypaypalupdate,
                paypalsuccessUpdate,
                successAfterPaypaypalupdatebuynow,
                successAfterPaypaypalBuynow,
                verifyPaypalstate,
                buynow_make_payment,
                buynow_reset_payment,
            }}
        >
            {props.children}
        </PayContext.Provider>
    )
}

export default PayState
