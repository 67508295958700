// for product posting
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const GET_EDIT_PRODUCT = 'GET_EDIT_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const SET_CURRENT = 'SET_CURRENT'
export const CLEAR_CURRENT = 'CLEAR_CURRENT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT'
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const GET_ALL_SELLER = 'GET_ALL_SELLER'
export const GET_ALL_SEARCH = 'GET_ALL_SEARCH'
export const GET_ALL_SIMILAR = 'GET_ALL_SIMILAR'
export const GET_SHIPPING_RATES = 'GET_SHIPPING_RATES'

// for auction posting
export const GET_EDIT_AUCTION = 'GET_EDIT_AUCTION'
export const GET_LA_SELLERS = 'GET_LA_SELLERS'
export const GET_NEW_AUCTION = 'GET_NEW_AUCTION'
export const GET_ALL_SELLER_AUCTIONS = 'GET_ALL_SELLER_AUCTIONS'
export const GET_ALL_SEARCH_AUCTIONS = 'GET_ALL_SEARCH_AUCTIONS'
export const GET_ALL_LOT_AUCTIONS = 'GET_ALL_LOT_AUCTIONS'

// for viewing in dashboard
export const GET_ACTIVE_BID_PRODUCT = 'GET_ACTIVE_BID_PRODUCT'
export const GET_WON_BID_PRODUCT = 'GET_WON_BID_PRODUCT'
export const GET_LOST_BID_PRODUCT = 'GET_LOST_BID_PRODUCT'

export const UPDATE_PROJ_DYN = 'UPDATE_PROJ_DYN'

export const GET_INVOICE = 'GET_INVOICE'
export const GET_INVOICE_NEW = 'GET_INVOICE_NEW'

export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_PRODUCT_VIEWED = 'GET_ALL_PRODUCT_VIEWED'

export const GET_OVER_ALL_CATEGORIES = 'GET_OVER_ALL_CATEGORIES'
export const GET_SUB_CATEGORIES = 'GET_SUB_CATEGORIES'
export const GET_SELLER_DASHBOARD = 'GET_SELLER_DASHBOARD'

export const UPCOMING_LIST = 'UPCOMING_LIST'

export const GET_ALL_UNPAID_USERS = 'GET_ALL_UNPAID_USERS'
export const GET_POS_BUYNOW_PRODUCTS = 'GET_POS_BUYNOW_PRODUCTS'
export const GET_ALL_BRAND_DETAILS = 'GET_ALL_BRANDS'
export const GET_ALL_DYNAMIC_FIELDS = 'GET_ALL_DYNAMIC'

export const GET_SIMILAR_PRODUCTS = 'GET_SIMILAR_PRODUCTS'
export const GET_CAT_AND_SUBCAT = 'GET_CAT_AND_SUBCAT'
export const GET_SIMILAR_PRODUCTS_QTY = 'GET_SIMILAR_PRODUCTS_QTY'
