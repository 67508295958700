import React, { useContext, useEffect } from 'react'
import CustomInput from '../components/atoms/Inputs/CustomInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CartContext from '../../Product/context/cart/cartContext'
const Qtyinput = (props) => {
    const cartContext = useContext(CartContext)
    const { updateFromCart, responseStatus, getUserCart } = cartContext
    const formik = useFormik({
        initialValues: {
            qty: props.qty,
            cart_id: props.cart_id,
            product_id: props.product_id,
        },
        validationSchema: Yup.object().shape({
            qty: Yup.number()
                .max(parseInt(props.total_qty), '0 Qty left!')
                .positive('Qty Invalid!')
                .typeError('Qty Invalid!')
                .integer('Qty Invalid!')
                .required('Please Enter Qty'),
        }),
        enableReinitialize: true,
        onSubmit: (values) => {
            updateFromCart(values)
        },
    })
    useEffect(() => {
        formik.setFieldValue('cart_id', props.cart_id)
        formik.setFieldValue('product_id', props.product_id)
    }, [props])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'updateusercart') {
                if (responseStatus.status === 'success') {
                    getUserCart()
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (formik.errors.qty) {
            props.setCartError(true)
        } else {
            props.setCartError(false)
        }
    }, [formik.errors.qty])

    return (
        <>
            <div className="col-12">
                <CustomInput
                    value={formik.values.qty}
                    onChange={formik.handleChange}
                    name="qty"
                    onBlurCapture={(e) => {
                        formik.handleSubmit(e)
                    }}
                    placeholder={'Enter Qty'}
                    type={'number'}
                    min={1}
                    error={formik.touched.qty && formik.errors.qty}
                    helperText={
                        formik.errors.qty
                            ? formik.errors.qty
                            : parseInt(props.total_qty) - formik.values.qty > 0
                            ? `${parseInt(props.total_qty) - formik.values.qty} qty left!`
                            : ''
                    }
                />
            </div>
        </>
    )
}

export default Qtyinput
